//import fonts scss
@import "./fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&family=Questrial&display=swap");

* {
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif !important;
  margin: 0;
  padding: 0;
}

body {
  // background: #eaf3ff !important;
  background: #fff;
  &.no-scroll {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

// FONT FAMILY
.fontGlobal {
  font-family: "Questrial", sans-serif !important;
}

// CUSTOM SCROLLBAR

.customScrollbar {
  // /* Webkit-based scrollbars (Chrome, Safari) */
  // &::-webkit-scrollbar {
  //   width: 4px; /* Consistent scrollbar width */
  //   background-color: #f7f8fa; /* Background of the scrollbar track */
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #f7f8fa; /* Track background */
  //   border-radius: 10px; /* Round the track corners */
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: cyan; /* The draggable thumb */
  //   // border-radius: 10px; /* Round the thumb corners */
  //   // border: 2px solid #f7f8fa; /* Ensures the thumb has a consistent appearance */
  // }

  // /* Hover effect for thumb (optional) */
  // &::-webkit-scrollbar-thumb:hover {
  //   background-color: #0d53fc; /* Change color on hover */
  //   // border: 2px solid #f7f8fa; /* Keep the border intact */
  // }

  /* Firefox scrollbar */
  scrollbar-width: thin; /* Use 'thin' for a consistent appearance */
  scrollbar-color: #0d53fc #f7f8fa; /* Thumb color and track color */
}

// COURSE DESCRIPTION TINYMCE

.courseDescription {
  .tox.tox-tinymce {
    border: none;
    .tox-editor-header {
      box-shadow: none;
      border-bottom: 1px solid #eef0f4;
    }
  }
}

.courseDescriptionPublic {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 1rem;
  }

  p {
    font-family: "Gilroy", sans-serif;
    font-weight: 400;
  }

  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  blockquote {
    font-family: "Gilroy", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    border-left: 4px solid #8f8fff;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
  }
  img {
    border-radius: 1rem;
  }
}

// THREE JS START
#threejs-tooltip {
  transition: opacity 0.2s ease-out;
  opacity: 0;
  visibility: hidden;
}
#threejs-tooltip.show {
  opacity: 1;
  visibility: visible;
}

// THREE JS END

.swiper.singleReportSlider.swiper-horizontal {
  padding-bottom: 1rem;
  .swiper-pagination {
    position: relative;
    bottom: -14px;
  }
}

@media only screen and (max-width: 768px) {
  .swiper.accountTabsSlider {
    width: 100%;
    .swiper-wrapper {
      .swiper-slide {
        &:first-child {
          padding-left: 1.5rem;
        }
        // &:last-child {
        //   padding-right: 1.5rem;
        // }
      }
    }
  }
}

// APEX CHARTS
.apexcharts-tooltip {
  background-color: #222b3c !important;
  border-color: #222b3c !important;
  color: #fff !important;
}

.apexcharts-tooltip.apexcharts-theme-dark::before {
  border-color: #333 transparent !important;
}
.custom-chart {
  .apexcharts-pie-series {
    path {
      filter: opacity(0.95);
    }
  }
  &:hover {
    cursor: pointer;
  }
  circle {
    &:hover {
      cursor: default;
    }
  }
}
.chakra-table {
  &.attendee-courses-table {
    thead {
      tr {
        th {
          &:nth-child(2) {
            width: 150px;
          }
        }
      }
    }
  }
}
// USE CASES SWIPER
.use-cases-swiper.swiper {
  padding-top: 1rem;
}

// REGISTER PROMO SWIPER
.registerPromo-swiper.swiper {
  width: 100%;
  .swiper-pagination {
    bottom: 0rem;
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #fff;
    }
  }
}

// SWIPER
.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  bottom: 0px;
}

// REACT-DATEPICKER CUSTOM
.customDatePicker {
  .react-datepicker-wrapper {
    width: 100%;
    font-family: "Gilroy", sans-serif;

    .react-datepicker__input-container {
      width: 100%;

      input {
        width: 100%;
        height: 3rem;
        border-radius: 0.75rem;
        border: 1px solid #eef0f4;
        padding: 0 1rem;
        font-weight: 500;
        color: #150a40;
        font-size: 0.938rem;

        &:focus-visible {
          outline: none;
          box-shadow: "none";
          border: 1px solid #8f8fff;
        }

        &:hover {
          border: 1px solid #ddd;
        }

        &.react-datepicker__input-container--invalid {
          border: 1px solid red;
        }
        &.isSmall {
          font-size: 0.875rem;
          height: 2.5rem;
        }
      }
    }
  }
}

.react-datepicker .chakra-select__wrapper select[aria-invalid="true"] {
  box-shadow: none;
  border: 1px solid #eef0f4;
}

.popoverDatePicker {
  .react-datepicker-popper {
    z-index: 9;
  }
  &.singleDatepicker {
    .react-datepicker {
      width: 300px;
    }
  }
  .react-datepicker {
    border: 1px solid #eef0f4;
    border-radius: 1rem;
    width: 382px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    padding-bottom: 1rem;

    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {
        border: none;
        background: none;
        color: #000;
        &:hover {
          background: none;
        }
        &::before {
          height: 6px;
          width: 6px;
          font-weight: 300;
          border-width: 2px 2px 0 0;
          // border: none;
          // background: none;
        }
      }
    }
    .react-datepicker__month-container {
      position: relative;

      .react-datepicker__month {
        position: relative;
        .react-datepicker__day {
          position: relative;
          color: #727190;
          font-size: 0.875rem;

          &.react-datepicker__day--disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }

          &.react-datepicker__day--selected {
            background: #8f8fff;
            color: #fff;
            font-weight: 500;
            &:hover {
              background: #3b3882;
            }
          }
          &.react-datepicker__day--in-selecting-range {
            background: #8f8fff;
            color: #fff;
            font-weight: 500;
            &:hover {
              background: #3b3882;
            }
          }
        }
      }

      .react-datepicker__header {
        padding: 0rem 0 0rem;
        border: 0;
        background: none;

        .react-datepicker__current-month {
          font-size: 0.875rem;
          font-weight: 500;
        }
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            font-size: 0.75rem;
            font-weight: 500;
            color: #727190;
          }
        }
      }
    }
    .react-datepicker__time-container {
      border-left: 1px solid #eef0f4;

      .react-datepicker__header--time {
        background: white;
        border: none;
        padding: 1.5rem 0 0.875rem;
        .react-datepicker-time__header {
          font-size: 0.875rem;
          font-weight: 500;
        }
      }

      .react-datepicker__time {
        position: relative;
        .react-datepicker__time-box {
          position: relative;
          .react-datepicker__time-list {
            position: relative;
            .react-datepicker__time-list-item {
              color: #727190;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
              font-size: 0.875rem;
              &:hover {
                background: #edf2f7;
              }
              &.react-datepicker__time-list-item--selected {
                background: #8f8fff;
                color: #fff;
                font-weight: 600;

                &:hover {
                  background: #3b3882;
                }
              }
            }
          }
        }
      }
    }
    .react-datepicker__children-container {
      // position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      // height: initial;
      // padding: 0;
      // margin: 0;
      // background: green;
    }
  }
}

.customPhone {
  .react-international-phone-country-selector-dropdown {
    // width: 600px;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.05);
    outline: 0;
    border: 1px solid #eef0f4;
    border-radius: 1rem;
    .react-international-phone-country-selector-dropdown__list-item {
      padding: 0.5rem 1rem;
    }
  }
}

.customPhone {
  width: 56px;
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      background: #fff;
      border-top-left-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
      height: 48px;
      width: 56px;
      border: none;
      border-right: 1px solid #eef0f4;

      // &:focus {
      //   outline: 0;
      // }
      .react-international-phone-country-selector-button__button-content {
        padding: 0 1rem;
        .react-international-phone-country-selector-button__flag-emoji {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  // .react-international-phone-input {
  //   width: 100%;
  //   border: 1px solid #ddd;
  //   background: #fff;
  //   color: #000;
  //   height: 48px;
  //   font-size: 1rem;
  //   font-weight: 500;
  //   border-top-right-radius: 0.75rem;
  //   border-bottom-right-radius: 0.75rem;
  // }

  &.customPhone-error {
    .react-international-phone-input-container {
      .react-international-phone-country-selector {
        .react-international-phone-country-selector-button {
          border: 1px solid red;
        }
      }
      .react-international-phone-input {
        border: 1px solid red !important;
      }
    }
  }
}

.appointmentPopoverDatepicker {
  &.isDisabled {
    // background: green;
    opacity: 0.4;

    &:hover {
      cursor: not-allowed;
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        color: #727190;
        border: 0px solid #ddd;
        background: none;
        padding: 0;
        margin: 0;
        text-transform: capitalize;
        width: 100%;
        &:hover {
          cursor: pointer;
        }
        &.datePopoverDatepickerNoDate {
          color: #dfdfdf;
          text-transform: none;
        }
      }
    }
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      color: #000;
      border: 1px solid #ddd;
      background: #fff;
    }
  }
}

.react-datepicker {
  font-family: "Gilroy", sans-serif;
  overflow: hidden;
}

.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;

  &:hover {
    border-right-color: #a0aec0;
  }
}

.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;

  &:hover {
    border-left-color: #a0aec0;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__header {
  border-radius: 0;
  background: #f7fafc;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container {
  .react-datepicker__time {
    .react-datepicker__time-box {
      ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        margin: 0 1px 0 0;
        height: auto;
        padding: 7px 10px;

        &:hover {
          background: #edf2f7;
        }
      }
    }
  }
}

.react-datepicker__day:hover {
  background: #edf2f7;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #3182ce;
  font-weight: normal;

  &:hover {
    background: #2a69ac;
  }
}

// .swiper.courseInfoSlider .swiper-pagination {
//   bottom: 0px;
// }
.swiper.courseInfoSlider {
  padding-bottom: 0.5rem;
}

// CUSTOM PLACEHOLDER COLOR

// /* For Webkit browsers */
// input::-webkit-input-placeholder {
//   color: #c6cbd4;
// }

// /* For Firefox */
// input:-moz-placeholder {
//   color: #c6cbd4;
// }

// /* For Firefox v19+ */
// input::-moz-placeholder {
//   color: #c6cbd4;
// }

// /* For Internet Explorer */
// input:-ms-input-placeholder {
//   color: #c6cbd4;
// }

// /* Generic placeholder */
// input::placeholder {
//   color: #c6cbd4;
// }

// CUSTOM PADDING TABLE
table {
  &.chakra-table {
    thead {
      tr {
        th {
          // padding: 1rem 1rem;
          padding: 1rem 0.5rem;
          &:first-child {
            padding-left: 2rem;
          }
          &:last-child {
            // font-size: 0;
            padding-right: 2rem;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          // padding: 1rem 1rem;
          padding: 1rem 0.5rem;
          &:first-child {
            padding-left: 2rem;
            padding-right: 0;
            width: 60px;
          }
          &:last-child {
            padding-right: 2rem;
          }
        }
      }
    }
  }
}

table {
  &.chakra-table.fullRecord {
    thead {
      tr {
        th {
          // padding: 1rem 1rem;
          padding: 0.875rem 0.5rem;
          &:first-child {
            padding-left: 2rem;
          }
          &:last-child {
            // font-size: 0;
            padding-right: 2rem;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          // padding: 1rem 1rem;
          padding: 0.5rem 0.5rem;
          &:first-child {
            padding-left: 2rem;
            padding-right: 0;
            width: 60px;
          }
          &:last-child {
            padding-right: 2rem;
          }
        }
      }
    }
  }
}

// CUSTOM AVATAR FONT SIZE
.chakra-avatar {
  .chakra-avatar__initials {
    font-size: 0.875rem;
    font-weight: 500;
  }
}

// CUSTOM PROGRESS BAR COLOR
.attendeesTotal {
  div {
    background-color: #0d53fc;
  }
}

.taskProgress {
  div {
    background-color: #0d53fc;
  }
}

// DO NOT DISPLAY UPLOAD INPUT
#file-upload {
  display: none;
}
#file-upload2 {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
  display: block;
}

/* .side-menu{
  position: fixed;
  background: #000;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 20px;
  transition: width .2s ease-in;
  z-index: 99;
} */

/* .side-menu.inactive{
  width: 80px;
} */

/* .side-menu .top-section{
  position: relative;
}

.side-menu .top-section .logo{
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
}
.side-menu .top-section .logo img{
  max-width: 100%;
  max-height: 100%;
}

.side-menu .top-section .toggle-menu-btn{
  color: #666;
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
} */

.side-menu.inactive .top-section .toggle-menu-btn {
  right: -50px;
}

.side-menu .search-controller {
  color: #fff;
  position: relative;
}

.side-menu .search-controller .search-btn {
  width: 40px;
  height: 35px;
  position: absolute;
  background: transparent;
  border: 0;
  font-size: 20px;
  color: #666;
}

.side-menu .search-controller input[type="text"] {
  border: 0;
  outline: none;
  height: 35px;
  background: #333;
  border-radius: 5px;
  display: block;
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  color: #666;
}

/* .side-menu .main-menu .menu-item{
  color: #333;
  text-decoration: none;
  font-size: 15px;
  display: block;
  font-weight: 600;
  cursor: pointer;
} */

/* .side-menu .main-menu .menu-item.active{
  background: #333;
  border-radius: 5px;
  color: #fff;
} */

/* .side-menu .main-menu{
  margin: 5px 0;
  position: relative;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
} */
/* not required any more */
/* .side-menu.inactive .main-menu{
 
} */

.side-menu .main-menu .menu-item .menu-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
}

.side-menu .main-menu .menu-item span {
  position: absolute;
  display: inline-block;
  line-height: 40px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu .main-menu .sub-menu {
  color: #333;
  margin-left: 20px;
  border-left: 1px solid #e8ebf2;
  box-sizing: border-box;
  padding-left: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
  list-style: none;
}

.side-menu .main-menu .sub-menu.active {
  max-height: 500px;
  overflow: auto;
}

.side-menu .main-menu .sub-menu a {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #9393ab;
  box-sizing: border-box;
  padding: 12px 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: 0.2s all;
}
.side-menu .main-menu .sub-menu a:hover {
  background: #f8f8f8;
  padding-left: 12px;
  color: #2a364e;
  transition: 0.2s all;
}

.side-menu .main-menu .sub-menu a.active {
  /* change in background, not recorded in video */
  background: #f8f8f8;
  display: block;
  color: #2a364e;
  font-weight: 600;
  padding-left: 12px;
}

.side-menu-footer {
  width: 100%;
  background: #333;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
}

.side-menu-footer .avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}
.side-menu-footer .avatar img {
  max-width: 100%;
}

.side-menu-footer .user-info {
  display: inline-block;
  margin: 0 10px;
  color: #fff;
  position: absolute;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .side-menu-footer .user-info {
  opacity: 1;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu-footer .user-info h5 {
  font-size: 15px;
}

.side-menu-footer .user-info p {
  font-size: 14px;
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #333;
}

.container {
  margin-left: 300px;
  transition: margin-left 0.2s ease-in;
  padding: 50px;
  box-sizing: border-box;
}

.container.inactive {
  margin-left: 80px;
}

@media (min-width: 1600px) {
  html {
    font-size: 16px; // Default base font size

    @media (min-width: 1600px) {
      font-size: 16px; // Increase font size for screens wider than 1600px
    }

    @media (min-width: 1800px) {
      font-size: 16px; // Further increase for ultra-wide screens
    }
  }
}
